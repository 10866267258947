import { render, staticRenderFns } from "./zhanghao.vue?vue&type=template&id=3e6d94a6&scoped=true&"
import script from "./zhanghao.vue?vue&type=script&lang=js&"
export * from "./zhanghao.vue?vue&type=script&lang=js&"
import style0 from "./zhanghao.vue?vue&type=style&index=0&id=3e6d94a6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e6d94a6",
  null
  
)

export default component.exports