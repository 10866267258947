<template>
  <div>
    <!-- tab切换 -->
    <div class="tabs_box">
      <div
        class="order_tabs"
        :class="{ active: active == index }"
        v-for="(item, index) in tabsList"
        :key="index"
        @click="changeTabs(index)"
      >
        {{ item }}
        <div v-if="active == index" class="border"></div>
      </div>
    </div>
    <div>
      <!-- 个人信息 -->
      <div v-if="active == 0" class="form_box">
        <el-form ref="form" :model="form" label-width="80px">
          <!-- 头像 -->
          <div class="tx_box">
            <div>头像：</div>
            <div
              class="tx_img_box"
              @mouseenter.stop="txShow = 1"
              @mouseleave.stop="txShow = 0"
            >
              <img class="tx_img" :src="info.face" alt />
              <!-- <el-upload
                v-if="txShow == 1"
                class="avatar-uploader change_tx"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <i>更换头像</i>
              </el-upload> -->
            </div>
          </div>
          <!-- 昵称 -->
          <div class="tx_box">
            <div>用户名：</div>
            <div>{{ info.username }}</div>
          </div>
          <!-- 昵称： -->
          <div class="tx_box">
            <div>昵称：</div>
            <input type="text" placeholder="选填" v-model="form.realName" />
          </div>
          <!-- 性别： -->
          <el-form-item label="性别：" label-width="80px">
            <el-radio-group
              v-model="info.sex"
              @change="changeSex"
              style="margin-left: 10px"
            >
              <el-radio label="0">女</el-radio>
              <el-radio label="1">男</el-radio>
              <el-radio label="2">保密</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 邮箱 -->
          <div class="tx_box">
            <div>邮箱：</div>
            <input type="text" placeholder="选填" v-model="form.email" />
          </div>
          <!-- 地址 -->
          <div class="tx_box">
            <div>地址：</div>
            <input type="text" placeholder="选填" v-model="form.address" />
          </div>
          <!-- 生日： -->
          <!-- <div class="tx_box">
            <div>生日：</div>
            <el-date-picker
              clear-icon="none"
              prefix-icon="none"
              v-model="value1"
              type="date"
              placeholder="选择日期"
            ></el-date-picker>
          </div> -->
          <!-- 所在城市： -->
          <!-- <div class="tx_box">
            <div>所在城市：</div>
            <v-distpicker province="广东省" city="广州市" area="海珠区" @selected="onSelected"></v-distpicker>
            <v-distpicker @selected="onSelected"></v-distpicker>
          </div> -->
          <!-- 个性签名： -->
          <!-- <div class="tx_box">
            <div>个性签名：</div>
            <textarea
              placeholder="请填写签名，20个字内"
              v-model="form.sign"
            ></textarea>
          </div>-->
          <el-form-item>
            <div @click="onSubmit" class="btn">保存</div>
          </el-form-item>
        </el-form>
      </div>
      <!-- 账号安全 -->
      <div v-if="active == 1" class="zh_anquan">
        <!-- 登录密码 -->
        <div class="anquan_box">
          <div class="anquan_left">
            <img src="../../assets/img/personal/icon_mm.png" alt />
            <div class="mima_box">
              <div>
                <div class="mima_tit">登录密码</div>
                <!-- <div class="mima_status">已设置</div> -->
                <!-- <div class="mima_status2">未设置</div> -->
              </div>
              <div class="mima_tip">用于保护账号信息和登录安全</div>
            </div>
          </div>
          <div class="anquan_rt">
            <div class="anquan_btn" @click="dialogVisible = true">修改密码</div>
          </div>
        </div>
        <!-- 手机号码 -->
        <div class="anquan_box">
          <div class="anquan_left">
            <img src="../../assets/img/personal/cion_sjh.png" alt />
            <div class="mima_box">
              <div>
                <div class="mima_tit">手机号码</div>
                <div class="mima_status" v-if="info.is_mobile == 1">{{info.mobile}}</div>
                <!-- <div class="mima_status2">未绑定</div> -->
              </div>
              <div class="mima_tip">
                可用手机号加密码登录，可通过手机号找回密码
              </div>
            </div>
          </div>
          <div class="anquan_rt">
            <div
              class="anquan_btn"
              @click="dialogVisible1 = true"
              v-if="info.is_mobile == 0"
            >
              立即绑定
            </div>
          </div>
        </div>
        <!-- <div class="anquan_box">
          <div class="anquan_left">
            <img src="../../assets/img/personal/icon_5_21.png" alt />
            <div class="mima_box">
              <div>
                <div class="mima_tit">提现密码</div>
                <div class="mima_status2" v-if="info.is_pay_pass == 1">
                  已设置
                </div>
              </div>
              <div class="mima_tip">设置密码，提现更安全</div>
            </div>
          </div>
          <div class="anquan_rt">
            <div
              class="anquan_btn"
              @click="dialogVisible2 = true"
              v-if="info.is_pay_pass == 0"
            >
              设置密码
            </div>
            <div
              class="anquan_btn"
              @click="dialogVisible3 = true"
              v-if="info.is_pay_pass == 1"
            >
              修改密码
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 修改密码弹框 -->
    <div class="editPaw">
      <el-dialog
        title="修改登录密码"
        :visible.sync="dialogVisible"
        width="500px"
        @close="editPswClose('ruleForm')"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="原始密码:" prop="old">
            <el-input
              v-model.number="ruleForm.old"
              placeholder="请输入原始密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="pass">
            <el-input
              type="password"
              auto-complete="new-password"
              v-model="ruleForm.pass"
              placeholder="请再次输入您的新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="checkPass">
            <el-input
              type="password"
              auto-complete="new-password"
              v-model="ruleForm.checkPass"
              placeholder="请再次输入您的新密码"
            ></el-input>
          </el-form-item>
          <el-form-item class="sure">
            <div class="forget" @click="goLogin">忘记密码</div>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <!-- 绑定手机号 -->
    <div class="editPaw">
      <el-dialog
        title="绑定手机号"
        :visible.sync="dialogVisible1"
        width="500px"
        @close="PhoneClose('ruleForm1')"
      >
        <el-form
          :model="ruleForm1"
          :rules="rules1"
          ref="ruleForm1"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="手机号码:" prop="phone">
            <el-input
              v-model.number="ruleForm1.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码:" prop="code" class="code">
            <el-input
              v-model.number="ruleForm1.code"
              placeholder="请输入验证码"
            ></el-input>
            <el-button class="get">获取验证码</el-button>
          </el-form-item>
          <el-form-item class="sure">
            <el-button type="primary" @click="submitForm1('ruleForm1')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <!-- 设置提现密码 -->
    <div class="editPaw">
      <el-dialog
        title="设置提现密码"
        :visible.sync="dialogVisible2"
        width="500px"
        @close="PhoneClose('ruleForm2')"
      >
        <el-form
          :model="ruleForm2"
          :rules="rules2"
          ref="ruleForm2"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="新密码:" prop="pass">
            <el-input
              auto-complete="new-password"
              v-model.number="ruleForm2.pass"
              placeholder="新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="checkPass">
            <el-input
              auto-complete="new-password"
              v-model.number="ruleForm2.checkPass"
              placeholder="请再次确认密码"
            ></el-input>
          </el-form-item>
          <el-form-item class="sure">
            <el-button type="primary" @click="submitForm2('ruleForm2')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <!-- 修改提现密码 -->
    <div class="editPaw">
      <el-dialog
        title="修改提现密码"
        :visible.sync="dialogVisible3"
        width="500px"
        @close="passClose1('ruleForm3')"
      >
        <el-form
          :model="ruleForm3"
          :rules="rules3"
          ref="ruleForm3"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="原始密码:" prop="old">
            <el-input
              v-model.number="ruleForm3.old"
              placeholder="请输入原始密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码:" prop="pass">
            <el-input
              type="password"
              auto-complete="new-password"
              v-model="ruleForm.pass"
              placeholder="请输入您的新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="checkPass">
            <el-input
              type="password"
              auto-complete="new-password"
              v-model="ruleForm3.checkPass"
              placeholder="请再次输入您的新密码"
            ></el-input>
          </el-form-item>
          <el-form-item class="sure">
            <el-button type="primary" @click="submitForm3('ruleForm3')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import VDistpicker from "v-distpicker";
import {
  modify_pass,
  cash_pass,
  changeInfo,
  getInfo,
} from "../../assets/js/personal";
export default {
  components: {
    VDistpicker,
  },
  data() {
    var oldPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入原始密码"));
      } else {
        callback();
      }
    };
    var oldPass1 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入原始密码"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.ruleForm2.checkPass !== "") {
          this.$refs.ruleForm2.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass4 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm2.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePass5 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.ruleForm3.checkPass !== "") {
          this.$refs.ruleForm3.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass6 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm3.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      sex2: "",
      URL: "http://v2.hnzye.cn/",
      info: "",
      txShow: 0,
      value1: "",
      active: 0,
      current: -1,
      tabsList: ["个人信息", "账号安全"],
      form: {
        realName: "",
        gender: 0,
        email: "",
        sign: "",
        address: "",
      },
      address: {
        province: "",
        city: "",
        county: "",
      },
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      ruleForm: {
        old: "",
        pass: "",
        checkPass: "",
      },
      ruleForm1: {
        phone: "",
        code: "",
      },
      ruleForm2: {
        pass: "",
        checkPass: "",
      },
      ruleForm3: {
        old: "",
        pass: "",
        checkPass: "",
      },
      rules: {
        old: [{ validator: oldPass, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
      rules1: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^((1[3584]\d{9}))$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "验证码", trigger: "blur" }],
      },
      rules2: {
        pass: [{ validator: validatePass3, trigger: "blur" }],
        checkPass: [{ validator: validatePass4, trigger: "blur" }],
      },
      rules3: {
        old: [{ validator: oldPass1, trigger: "blur" }],
        pass: [{ validator: validatePass5, trigger: "blur" }],
        checkPass: [{ validator: validatePass6, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.info = JSON.parse(window.sessionStorage.getItem("info"));
    // this.info.sex = 0
    // console.log(this.info);
  },
  methods: {
    changeSex(e) {
      // console.log(e);
      this.sex2 = e;
    },
    // 更换头像
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

    changeTabs(i) {
      this.active = i;
    },
    onSubmit() {
      if (
        !this.form.realName &&
        !this.form.email &&
        !this.form.address &&
        !this.sex2
      ) {
        this.$message.error("请先填写修改内容！");
        return;
      }
      // 修改个人信息
      changeInfo({
        id: JSON.parse(window.localStorage.getItem("userInfo"))
          ? JSON.parse(window.localStorage.getItem("userInfo")).id
          : "",
        nickname: this.form.realName || "",
        sex: this.sex2 || "",
        email: this.form.email || "",
        address: this.form.address || "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message.success("修改成功！");
          this.form = {};
          getInfo({
            id: JSON.parse(window.localStorage.getItem("userInfo"))
              ? JSON.parse(window.localStorage.getItem("userInfo")).id
              : "",
          }).then((res) => {
            this.$emit("func", res.data.info);
          });
        }
      });
    },
    onSelected(data) {
      this.address.province = data.province.value;
      this.address.city = data.city.value;
      this.address.county = data.area.value;
    },
    // 关闭修改密码弹框 重置
    editPswClose(formName) {
      // console.log(this.$refs[formName]);
      this.$refs[formName].resetFields();
    },
    // 绑定手机号 重置
    PhoneClose(formName1) {
      this.$refs[formName1].resetFields();
    },
    // 设置提现 重置
    passClose(formName2) {
      this.$refs[formName2].resetFields();
    },
    // 修改提现密码 重置
    passClose1(formName3) {
      this.$refs[formName3].resetFields();
    },
    // 修改密码
    goLogin() {
      this.dialogVisible = true;
      this.$router.push({ path: "/login", query: { active: 2 } });
    },
    submitForm(formName) {
      // console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let id = JSON.parse(window.localStorage.getItem("userInfo")).id;
          let old_pass = this.ruleForm.old;
          let new_pass = this.ruleForm.pass;
          let confirm_pass = this.ruleForm.checkPass;
          modify_pass({ id, old_pass, new_pass, confirm_pass }).then((res) => {
            this.$message.success("修改成功");
            this.dialogVisible = false;
          });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    submitForm1(formName1) {
      // console.log(formName1);
      this.$refs[formName1].validate((valid) => {
        // console.log(valid);
        if (valid) {
          // console.log("111");
          alert("submit!");
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    submitForm2(formName2) {
      this.$refs[formName2].validate((valid) => {
        // console.log(valid);
        if (valid) {
          let id = JSON.parse(window.localStorage.getItem("userInfo")).id;
          let new_pass = this.ruleForm2.pass;
          let confirm_pass = this.ruleForm2.checkPass;
          cash_pass({ id, new_pass, confirm_pass }).then((res) => {
            this.$message.success("设置成功");
            this.dialogVisible2 = false;
            this.info.is_pay_pass = 1;
          });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取个人信息
    submitForm3(formName3) {
      this.$refs[formName3].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.distpicker-address-wrapper {
  select {
    padding: 0px !important;
    height: 30px !important;
    font-size: 14px !important;
    line-height: 30px !important;
    color: #666;
    border: 1px solid #ccc;
  }
  option {
    color: #333;
  }
}
@deep: ~">>>";
input::-webkit-input-placeholder {
  color: #999;
  font-size: 14px;
}
@deep: ~">>>";
input::-moz-input-placeholder {
  color: #999;
  font-size: 14px;
}
@deep: ~">>>";
input::-ms-input-placeholder {
  color: #999;
  font-size: 14px;
}
input,
textarea,
select {
  outline: none;
}
.active {
  color: #0c69e6;
}
.tabs_box {
  display: flex;
  font-size: 16px;
  color: #656565;
  border-bottom: 1px solid #dddddd;
  //  width: 900px;
  .order_tabs {
    height: 64px;
    line-height: 60px;
    // margin-right: 60px;
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;
    .border {
      // margin-top: 10px;
      border-bottom: 4px solid rgba(12, 106, 231, 1);
    }
  }
}
.form_box {
  width: 700px;
  margin-top: 40px;
  margin-left: 30px;
  font-size: 14px;
  color: #333;
  .tx_box {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    div:nth-child(1) {
      width: 90px;
      text-align: center;
      // margin-right: 10px;
    }
    .tx_img_box {
      position: relative;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      .tx_img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        // cursor: pointer;
      }
      .change_tx {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 35px;

        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
        background-color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
      }
    }

    input {
      width: 230px;
      height: 30px;
      border: 1px solid rgba(204, 204, 204, 1);
      border-radius: 4px;
      padding-left: 10px;
      box-sizing: border-box;
    }
    textarea {
      width: 350px;
      height: 70px;
      border: 1px solid rgba(204, 204, 204, 1);
      border-radius: 4px;
      resize: none;
      padding: 10px;
      box-sizing: border-box;
    }
    @deep: ~">>>";
    .el-date-editor.el-input,
    .el-input--prefix .el-input__inner {
      width: 230px;
      height: 30px;
      //   padding-left: 10px;
    }
    @deep: ~">>>";
    .el-input__inner {
      padding-left: 10px;
      border: 1px solid #ccc;
    }
  }
  .btn {
    width: 120px;
    height: 40px;
    background: rgba(12, 106, 231, 1);
    border-radius: 20px;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
}
.zh_anquan {
  margin: 0 30px;
}
.anquan_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  border-bottom: 1px solid rgba(242, 242, 242, 1);
  .anquan_left {
    display: flex;
    img {
      width: 36px;
      height: 36px;
      margin-right: 20px;
    }
    .mima_box {
      > div {
        display: flex;
        align-items: center;
      }
      .mima_tit {
        font-size: 16px;
        color: #333;
        font-weight: 700;
        margin-right: 15px;
      }
      .mima_status {
        font-size: 12px;
        color: #666;
      }
      .mima_status2 {
        color: #f13232;
        font-size: 12px;
      }
      .mima_tip {
        font-size: 12px;
        color: #999;
        margin-top: 5px;
      }
    }
  }
  .anquan_rt {
    .anquan_btn {
      width: 100px;
      height: 40px;
      border: 1px solid #0090f0;
      border-radius: 20px;
      font-size: 14px;
      color: #0090f0;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.tx_dialog {
  .el-dialog {
    width: 510px;
    height: 446px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    text-align: center;
    .el-dialog__title {
      font-weight: bold;
    }
    .change_txImg {
      width: 190px;
      height: 190px;
    }
    .change_img {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      div {
        font-size: 14px;
        color: #333;
        cursor: pointer;
        margin: 0 20px;
      }
    }
    .dialog-footer {
      display: flex;
      justify-content: center;
      div {
        width: 120px;
        height: 40px;
        border-radius: 20px;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
      div:nth-child(1) {
        background: rgba(12, 106, 231, 1);
        color: #fff;
        margin-right: 27px;
      }
      div:nth-child(2) {
        border: 1px solid rgba(204, 204, 204, 1);
        color: #999;
      }
    }
  }
}
/deep/ .el-dialog__header {
  text-align: center;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
}
.editPaw {
  /deep/ .el-dialog {
    padding: 10px 30px 30px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    box-sizing: border-box;
    /deep/ .el-dialog__body {
      padding: 20px 0 0;
    }

    .el-form {
      .sure {
        margin-bottom: 0;
        /deep/ .el-form-item__content {
          display: flex;
          justify-content: flex-end;
          .forget {
            cursor: pointer;
            font-size: 12px;
            color: #999999;
            margin-right: 30px;
          }
        }
      }
      /deep/ .el-form-item__label {
        padding: 0 25px 0 0;
      }
      /deep/ .el-input__inner {
        background-color: #f2f2f2;
      }
      // 处理验证码
      .code {
        /deep/ .el-form-item__content {
          display: flex;
          .get {
            margin-left: 10px;
            color: #0c6ae7;
            background-color: #f2f2f2;
          }
        }
      }
    }
  }
}
</style>